<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Click the buttons below to complete the
        <span class="text-bold">condensed</span> electron configuration for the each of the
        atoms/ions indicated below.
      </p>

      <p class="mb-2">
        <stemble-latex content="$\text{a) }\ce{Fe^3+}$" />
      </p>

      <electron-configuration-input
        v-model="inputs.input1"
        :max-n="6"
        :max-occupation="14"
        :orbital-types="['s', 'p', 'd', 'f']"
        class="mb-5"
        prefix="\mathrm{[Ar]}"
      />

      <p class="mb-2">
        <stemble-latex content="$\text{b) }\ce{Au}$" />
      </p>

      <electron-configuration-input
        v-model="inputs.input2"
        :max-n="6"
        :max-occupation="14"
        :orbital-types="['s', 'p', 'd', 'f']"
        class="mb-5"
        prefix="\mathrm{[Xe]}"
      />

      <p class="mb-2">
        <stemble-latex content="$\text{c) }\ce{As^3-}$" />
      </p>

      <electron-configuration-input
        v-model="inputs.input3"
        :max-n="6"
        :max-occupation="14"
        :orbital-types="['s', 'p', 'd', 'f']"
        class="mb-5"
        prefix="\mathrm{[Ar]}"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ElectronConfigurationInput from '../inputs/ElectronConfigurationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'CalPolyPracticeExam3Q12',
  components: {
    StembleLatex,
    ElectronConfigurationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: [],
        input3: [],
      },
    };
  },
};
</script>
